import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import classNames from 'classnames';
import { LottieProperties } from 'components/template-designer/types/layerProperties.type';

interface Props {
    name: string;
    visibility: number[];
    lottieAnimation: LottieProperties['lottieAnimation'];
    layerAttributes: any;
    id: string;
}

/* Wrapper component to render a Lottie animation */
const LottieWrapper = ({ layerAttributes, lottieAnimation, visibility, name, id }: Props) => {
    const lottieRef = useRef(null);

    useEffect(() => {
        if (!lottieAnimation || !lottieRef?.current) return;

        lottie.loadAnimation({
            name,
            container: lottieRef.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: typeof lottieAnimation === 'string' ? lottieAnimation : lottieAnimation.url
        });

        return () => lottie.destroy(name);
    }, [lottieAnimation?.url]);

    return (
        <div
            {...layerAttributes}
            data-lottie-name={name}
            className={classNames(layerAttributes.className)}
            id={id}
            ref={lottieRef}
            data-visibility={visibility}
        />
    );
};

export default LottieWrapper;
