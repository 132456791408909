import React, { useMemo } from 'react';
import classNames from 'classnames';
import Moveable from 'react-moveable';
import get from 'lodash/get';
import { TDzoomLevel } from 'types/event-emitter.type';
import { KeyboardShortcutScope, KeyboardShortcutScopes } from 'hooks/useKeyboardShortcuts';
import LayerType from 'components/template-designer/types/layer.type';
import Format from 'components/template-designer/types/format.type';
import Template, { DesignerSettings, State, View } from 'components/template-designer/types/template.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { Guideline } from 'components/template-designer/types/formatProperties.type';
import { useIsValueChanged } from 'components/template-designer/hooks/useIsValueChanged';
import { getSelectedLayerBorder } from 'components/template-designer/utils/calculateSelectedLayerBorder';
import { LayerPropertiesHelpers } from 'components/template-designer/helpers/layer-properties.helpers';
import Layer from './layer';
import useEditor from '../hooks/useEditor';
import { NewPosition } from '../types/types';
import '../styles/editor.scss';

interface Props {
    format: Format;
    frameType: View['frameType'];
    zoomLevel: TDzoomLevel;
    layer: LayerType;
    layoutGrid: Guideline[] | undefined;
    onClickLayer: (targetLayer: string | null, ctrlClick: boolean, doubleClick?: boolean) => void;
    saveLayer: (
        position: NewPosition['position'],
        positionChanged: NewPosition['positionChanged'],
        widthChanged: NewPosition['widthChanged'],
        heightChanged: NewPosition['heightChanged'],
        rotationChanged: NewPosition['rotationChanged'],
        format?: Format
    ) => void;
    layersShouldReverse: boolean;
    keepRatio?: boolean;
    currentTime: number;
}

interface StoreData {
    layers: Template['layers'];
    layerProperties: Template['layerProperties'];
    selectedFormats: State['selectedFormats'];
    showComments: View['showComments'];
    showVersionHistory: View['showVersionHistory'];
}

const CONTROLS_SCALE = 0.9;

const Editor = ({ format, zoomLevel, layer, layoutGrid, onClickLayer, saveLayer, layersShouldReverse, currentTime }: Props) => {
    const frameType = useMemo(() => getTemplateData<View['frameType']>('view.frameType'), []);
    const isCurrentTimeChanged = useIsValueChanged(currentTime, 500);

    const { layers, layerProperties, selectedFormats, showComments, showVersionHistory } = useComponentStore<StoreData>('TemplateDesigner', {
        fields: {
            layers: 'layers',
            layerProperties: `layerProperties`,
            selectedFormats: 'state.selectedFormats',
            showComments: 'view.showComments',
            showVersionHistory: 'view.showVersionHistory'
        }
    });

    // If the layer does not exist anymore return null.
    const currentLayerProperties = get(layerProperties, `general.${frameType}.${layer.key}`);

    // Merge the general layer properties with the format layer properties.
    const { layerProps } = useMemo(
        () => LayerPropertiesHelpers.mergeLayerProperties(layer.key, frameType, [format.key], layerProperties),
        [layerProperties, layer.key, format]
    );

    const {
        moveableRef,
        showEditor,
        altKeyPressed,
        shiftKeyPressed,
        showDragLayer,
        dragLayer,
        targetLayer,
        showControls,
        dragTarget,
        onDragLayerStart,
        onDragLayer,
        onDragLayerEnd,
        onResizeLayerStart,
        onResizeLayer,
        onResizeLayerEnd,
        onRotateLayerStart,
        onRotateLayer,
        onRotateLayerEnd
    } = useEditor({
        layers,
        layer,
        layerProperties: layerProps,
        format,
        selectedFormats,
        zoomLevel,
        saveLayer,
        layoutGrid,
        currentTime
    });

    const handleOnClickDraggableLayer = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        KeyboardShortcutScope.setScope(KeyboardShortcutScopes.TDEditor);
        const ctrlClick = event.ctrlKey || event.metaKey;
        if (event.detail === 1 && !ctrlClick) return;

        const targetElement = event.target as HTMLDivElement;
        targetElement.style.pointerEvents = 'none';

        // Find the element below the current target at the specified coordinates
        const elementBelow = document.elementFromPoint(event.clientX, event.clientY) as HTMLElement;

        if (!elementBelow) return;

        const layerKey = elementBelow.dataset.layerKey ?? null;
        if (!layerKey) return;
        const doubleClick = event.detail === 2;
        onClickLayer(layerKey, ctrlClick, doubleClick);
        targetElement.style.pointerEvents = 'auto';
    };

    const overlayPosition: React.CSSProperties = {
        left: dragLayer.left,
        top: dragLayer.top,
        right: dragLayer.right,
        bottom: dragLayer.bottom,
        width: dragLayer.width,
        height: dragLayer.height,
        transform: dragLayer.transform
    };

    const showEdgeControls = (() => {
        const layerWidthUnit = layerProps.properties.width.unit;
        const layerHeightUnit = layerProps.properties.height.unit;
        const layerWidthValue = layerProps.properties.width.value;
        const layerHeightValue = layerProps.properties.height.value;
        const layerWidth = layerWidthValue as number;
        const layerHeight = layerHeightValue as number;

        if (layerWidthUnit !== 'px' || layerHeightUnit !== 'px') return false;
        if (zoomLevel <= 0.3) return layerWidth >= 200 && layerHeight >= 200;
        if (zoomLevel <= 0.5) return layerWidth >= 150 && layerHeight >= 150;
        if (zoomLevel <= 1) return layerWidth >= 100 && layerHeight >= 100;
        if (zoomLevel <= 1.5) return layerWidth >= 75 && layerHeight >= 75;
        if (zoomLevel <= 2) return layerWidth >= 50 && layerHeight >= 50;
        return false;
    })();

    if (!showEditor || !currentLayerProperties) return null;

    return (
        <>
            {/* Ghost layer when the alt key is pressed to copy the layer. */}
            {altKeyPressed &&
                !showDragLayer &&
                (() => {
                    const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
                    const customCSS = getTemplateData<DesignerSettings['customCSS']>('designerSettings.customCSS');
                    const customJS = getTemplateData<DesignerSettings['customJS']>('designerSettings.customJS');

                    return (
                        <Layer
                            customLayerProperties={layerProperties}
                            frameType={frameType}
                            layer={layer}
                            format={format}
                            showHover={false}
                            customCSS={customCSS}
                            customJS={customJS}
                            totalLayers={0}
                            currentLayerIndex={0}
                            layersShouldReverse={layersShouldReverse}
                        />
                    );
                })()}

            {!isCurrentTimeChanged && !showVersionHistory && (
                <div
                    className={`template-designer__editor__selected-layer ${format.key}`}
                    style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        boxShadow: getSelectedLayerBorder(zoomLevel),
                        ...overlayPosition
                    }}
                />
            )}

            {/* Drag layer so the layer can always be dragged even if it is behind another layer. */}
            {!showVersionHistory && (
                <div
                    style={{ zIndex: 2000, position: 'absolute', cursor: 'move', ...overlayPosition }}
                    className={`drag-target ${format.key} ${layer.key}`}
                    id="drag-target"
                    data-layer-key={layer.key}
                    data-format-key={format.key}
                    onClick={handleOnClickDraggableLayer}
                />
            )}

            {/* When the shift key is pressed the layer is dragged along x, y and diagonal axes the canvas below is used to draw the guidelines showing thes axes */}
            {shiftKeyPressed && <canvas className={`template-designer__editor__shift-drag-guide ${format.key}`} />}
            <canvas className={`template-designer__editor__temporary-guides ${format.key}`} />

            {!showComments && !showVersionHistory && !isCurrentTimeChanged && (
                <Moveable
                    className={classNames('template-designer__editor', {
                        ['template-designer__editor--edge-controls']: showEdgeControls,
                        ['template-designer__editor--edge-controls-zoomed-in']: zoomLevel > 1.3,
                        ['template-designer__editor--show-controls']: showControls
                    })}
                    useMutationObserver
                    useResizeObserver
                    ref={moveableRef}
                    target={targetLayer}
                    linePadding={15}
                    dragTarget={dragTarget !== null ? dragTarget : undefined}
                    origin={false}
                    zoom={CONTROLS_SCALE / zoomLevel}
                    draggable={!layer.locked}
                    resizable={!layer.locked}
                    rotatable={!layer.locked}
                    onDragStart={onDragLayerStart}
                    onDrag={onDragLayer}
                    onDragEnd={onDragLayerEnd}
                    onResizeStart={onResizeLayerStart}
                    onResize={onResizeLayer}
                    onResizeEnd={onResizeLayerEnd}
                    onRotateStart={onRotateLayerStart}
                    onRotate={onRotateLayer}
                    onRotateEnd={onRotateLayerEnd}
                />
            )}
        </>
    );
};

export default Editor;
