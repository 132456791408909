import React, { useEffect, useRef, memo } from 'react';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import classNames from 'classnames';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import LayerType from 'components/template-designer/types/layer.type';
import Format from 'components/template-designer/types/format.type';
import { StylingHelpers } from 'components/template-designer/helpers/styling.helpers';
import Template, { DesignerSettings, View } from 'components/template-designer/types/template.type';
import LayerProperties, { FontSizeUnitOptions, ResizeOptions, TextProperties } from 'components/template-designer/types/layerProperties.type';
import { TimelineHelpers } from 'components/template-designer/helpers/timeline.helpers';
import { TemplateVersionHelpers } from 'components/template-designer/helpers/template-version.helpers';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { HugLayerChanged } from 'components/template-designer/types/event.type';
import { SceneHelpers } from 'helpers/scene.helpers';
import FrameTypeHelpers from 'components/template-designer/helpers/frame-type.helpers';
import { ConfigHelpers } from 'components/template-designer/helpers/config.helpers';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { defaultFormatProperties } from 'components/template-designer/config/layer-properties/default-format-properties';
import { RegexHelpers } from 'helpers/regex.helpers';
import cssStringToObject from '../utils/cssStringToObject';
import LottieWrapper from './lottie-wrapper';
import shrinkText from '../utils/shrinkText';
import '../styles/layer.scss';

interface Props {
    frameType: View['frameType'];
    customLayerProperties?: Template['layerProperties'];
    layer: LayerType;
    format?: Format;
    changeLayerText?: (value: TextProperties['text'], layerKey: LayerType['key']) => void;
    editingTextLayerKey?: string | null;
    showHover?: boolean;
    customCSS: DesignerSettings['customCSS'];
    customJS: DesignerSettings['customJS'];
    totalLayers: number;
    currentLayerIndex: number;
    layersShouldReverse: boolean;
    preview?: boolean;
    dataCyPrefix?: string;
    layerPreview?: boolean;
}

const Layer = memo((props: Props) => {
    const {
        frameType,
        customLayerProperties,
        layer,
        format,
        changeLayerText,
        editingTextLayerKey,
        showHover,
        customCSS,
        customJS,
        totalLayers,
        currentLayerIndex,
        layersShouldReverse,
        preview,
        dataCyPrefix,
        layerPreview
    } = props;

    const fields: Record<string, string> = {
        generalProperties: `layerProperties.general.${frameType}.${layer.key}`,
        highlightedCharacter: 'designerSettings.highlightedCharacter'
    };

    if (format) {
        fields.formatProperties = `layerProperties.${format.key}.${frameType}.${layer.key}`;
    }

    const { generalProperties, formatProperties, highlightedCharacter } = useComponentStore<{
        generalProperties: LayerProperties;
        formatProperties: LayerProperties;
        highlightedCharacter: DesignerSettings['highlightedCharacter'];
    }>('TemplateDesigner', {
        fields
    });

    const customGeneralProperties = get(customLayerProperties, `general.${frameType}.${layer.key}`) as LayerProperties;
    const customFormatProperties = format && (get(customLayerProperties, `${format.key}.${frameType}.${layer.key}`) as LayerProperties);

    const generalLayerProperties: LayerProperties = cloneDeep(customGeneralProperties ? customGeneralProperties : generalProperties);
    const formatLayerProperties: LayerProperties = cloneDeep(customFormatProperties ? customFormatProperties : formatProperties);
    const layerRef = useRef<HTMLDivElement>(null);

    // When an object is empty, we get an empty array back from the back end. If that happens, then the Lodash merge with fail resulting in the highlighted text to be empty. Reset this to an empty object resolves this issue. But this can be improved somehow in the future.
    if (
        layer.type === 'text' &&
        (formatLayerProperties?.properties as TextProperties)?.textStyling &&
        (formatLayerProperties?.properties as TextProperties)?.textStyling.highlighted &&
        !Object.keys((formatLayerProperties?.properties as TextProperties)?.textStyling.highlighted).length
    ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (formatLayerProperties.properties as TextProperties).textStyling.highlighted = {};
    }

    const combinedLayerProps = mergeWith(generalLayerProperties, formatLayerProperties, (general, format) => {
        if (Array.isArray(format) && format.length === 0) {
            return general;
        }
    });

    const customCSSStyles = customCSS ? formatLayerProperties?.properties?.customCSS || generalLayerProperties.properties.customCSS || '' : '';
    const layerStyle = StylingHelpers.getLayerStyle(combinedLayerProps.properties, combinedLayerProps.animations, layer.type, format);
    const customCssObject = cssStringToObject(customCSSStyles).attributes;

    // TODO
    let style: any = mergeWith(layerStyle, customCssObject);

    delete style.imageFileSize;
    delete style.backgroundImageFileSize;

    if (layerStyle.transform && customCssObject.transform) {
        style.transform = layerStyle.transform + ' ' + customCssObject.transform;
    }

    // Apply hover styling
    if (showHover) {
        const combinedHoverProps = mergeWith(get(generalLayerProperties, `hover.properties`), get(formatLayerProperties, `hover.properties`));

        // if the background is not solid or transparent, then we need to disable the background hover.
        const isBackgroundDisabled =
            combinedLayerProps.properties.background &&
            combinedLayerProps.properties.background.type !== 'solid' &&
            combinedLayerProps.properties.background.type !== 'transparent';

        if (isBackgroundDisabled) {
            combinedHoverProps.background = combinedLayerProps.properties.background;
        }

        const hoverStyle = StylingHelpers.getHoverStyle(style.transform, combinedHoverProps);
        style = mergeWith(style, hoverStyle);
    }

    const zIndex = (() => {
        if (style.zIndex !== undefined) {
            return style.zIndex;
        }

        return (layersShouldReverse ? currentLayerIndex : totalLayers - currentLayerIndex) + 1;
    })();

    // collection of all the layer attributes
    const layerAttributes = {
        key: layer.key,
        id: layer.key,
        ['data-layer-key']: layer.key,
        ['data-cy']: `${dataCyPrefix}-${layer.type}${layer.key}-div`,
        className: `layers-container__layer ${customJS ? style.customClassname ?? '' : ''} ${preview ? 'preview' : 'layers-container__layer--locked'} ${
            layer.locked ? '' : ''
        } ${layer.key}`,
        style: {
            ...style,
            zIndex
        }
    };

    if (format) {
        layerAttributes['data-format-key'] = format.key;
        layerAttributes['className'] += ` ${format.key}`;
    }

    if (layerPreview) {
        layerAttributes.style.position = 'static';
        layerAttributes.style.transform = 'none';
        layerAttributes.style.top = 'auto';
        layerAttributes.style.right = 'auto';
        layerAttributes.style.bottom = 'auto';
        layerAttributes.style.left = 'auto';
        layerAttributes.style.display = 'flex';
    }

    /**
     * If the text is being edited, then focus on the text.
     */
    useEffect(() => {
        if (layerRef?.current) {
            if (editingTextLayerKey === layer.key) {
                layerRef.current.focus();
            } else {
                layerRef.current.blur();
            }
        }
    }, [editingTextLayerKey]);

    // if the text is shrink to fit then add a mutation observer to shrink the text when it is too big for the container
    useEffect(() => {
        if (layer.type !== 'text') return;

        /**
         * Check if the shrink text option is disabled.
         */
        const shrinkTextDisabled = ConfigHelpers.isShrinkTextDisabled(combinedLayerProps.properties.width, combinedLayerProps.properties.height);

        if (!combinedLayerProps.properties?.canEdit?.scaleText || shrinkTextDisabled) {
            if (layerRef.current !== null) {
                layerRef.current.style.fontSize = layerAttributes.style.fontSize;
            }
            return;
        }

        if (layerRef.current !== null) {
            const layerFontSizeInPx = (() => {
                const fontSize = parseInt(style.fontSize);

                if (style.fontSize.includes(FontSizeUnitOptions.Px)) {
                    return fontSize;
                } else {
                    const formatFontSize: number =
                        TemplateDesignerStore.getModelWithFallback([
                            `layerProperties.${format?.key}.${frameType}.properties.textStyling.fontSize.value`,
                            `layerProperties.general.${frameType}.properties.textStyling.fontSize.value`
                        ]) ?? defaultFormatProperties.textStyling?.normal.fontSize;
                    return formatFontSize * fontSize;
                }
            })();

            shrinkText(layerRef.current, layerFontSizeInPx);
        }
    }, [
        layerAttributes.style.fontSize,
        combinedLayerProps?.properties?.canEdit?.scaleText,
        style.text,
        combinedLayerProps.properties.width,
        combinedLayerProps.properties.height
    ]);

    /**
     * If a layer with the hug as a resize option changed, then we need to send out a custom event.
     * This is used to update the size in layer edit.
     */
    useEffect(() => {
        if (layerPreview || preview) return;
        if (combinedLayerProps.properties.width?.resize !== ResizeOptions.Hug && combinedLayerProps.properties.height?.resize !== ResizeOptions.Hug) return;

        /**
         * Sent out a custom event.
         */
        const event = new CustomEvent<HugLayerChanged>('TextChanged', {
            detail: {
                layerKey: layer.key
            }
        });
        window.dispatchEvent(event);
    }, [layerAttributes.style.fontSize, style.text]);

    if (layer.type === 'text') {
        const highlightedTextStyling = StylingHelpers.getTextStyle(combinedLayerProps.properties, {}, 'highlighted', format);

        /**
         * If gradient is in the color, then we need to apply the gradient to the text.
         * These properties also need to be applied to the span tag. Otherwise it won't work for some browers.
         */
        const textSpanStyling = {};
        if (style.color?.includes('gradient')) {
            textSpanStyling['background'] = style.color;
            textSpanStyling['textFillColor'] = 'transparent';
            textSpanStyling['WebkitTextFillColor'] = 'transparent';
            textSpanStyling['WebkitBackgroundClip'] = 'text';
            if (TemplateVersionHelpers.shouldUseDisplayBlockForGradientText()) {
                // The display should be block for the gradient to be visible as expected.
                // The padding is needed for the lower letters to always fall within the gradient.
                textSpanStyling['paddingTop'] = '0.2em';
                textSpanStyling['paddingBottom'] = '0.2em';
                textSpanStyling['display'] = 'block';
            }

            //If the text color is a gradient the text decoration will be black
            layerAttributes.style.color = '#000000';
        }

        if (layerAttributes.style.textShadow) {
            if (layerAttributes.style.textShadow === 'unset') {
                textSpanStyling['filter'] = `unset`;
            } else {
                textSpanStyling['filter'] = `drop-shadow(${layerAttributes.style.textShadow})`;
            }
            delete layerAttributes.style.textShadow;
        } else {
            textSpanStyling['filter'] = `unset`;
        }

        let textBackgroundColor, textPadding;

        /**
         * If text background is enabled, then we need to apply the background color and padding to each span.
         * And remove it from the layerAttributes.style object, otherwise it will be applied to the container also.
         */
        if (layerAttributes.style.textBackground) {
            textBackgroundColor = layerAttributes.style.background || layerAttributes.style.backgroundColor;
            textPadding = layerAttributes.style.padding;
            delete layerAttributes.style.textBackground;
            delete layerAttributes.style.padding;
            delete layerAttributes.style.background;
            delete layerAttributes.style.backgroundColor;
        }

        /**
         * Remove certain custom css properties for text span.
         * Otherwise these properties will also be applied on the span and results in double the result.
         */
        const textCustomCSS = cloneDeep(customCssObject);
        delete textCustomCSS.padding;
        delete textCustomCSS.margin;
        delete textCustomCSS.transform;
        delete textCustomCSS.opacity;

        // Get the text with highlighted styling.
        const text = StylingHelpers.getLayerText(style.text, highlightedTextStyling, highlightedCharacter, {
            color: textBackgroundColor,
            padding: textPadding
        });

        // If color or text changes, we need to re-render the text layer.
        const key = style.color + ' ' + text;

        const editingText = editingTextLayerKey === layer.key;

        return (
            <div
                {...layerAttributes}
                style={{
                    ...layerAttributes.style,
                    display: editingText ? 'block' : layerAttributes.style.display
                }}
                ref={layerRef}
                className={classNames(layerAttributes.className, editingText && 'layer-text-edit-active')}
                contentEditable={editingText}
                suppressContentEditableWarning
                onBlur={(e: React.FocusEvent<HTMLElement>) => {
                    changeLayerText?.(e.target.innerText, layer.key);
                }}
                onFocus={(e: React.FocusEvent<HTMLElement>) => {
                    const selection = window.getSelection();
                    if (!selection) return;
                    const range = document.createRange();
                    range.selectNodeContents(e.target);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }}>
                <span
                    key={key}
                    style={{
                        pointerEvents: 'none',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        height: undefined,
                        aspectRatio: undefined,
                        verticalAlign: 'inherit',
                        userSelect: 'none',
                        ...textSpanStyling,
                        ...textCustomCSS
                    }}
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                />
            </div>
        );
    }

    if (layer.type === 'image') {
        const src = layerAttributes.style.src;

        // If there is no src, use a normal div to avoid the broken image icon.
        if (!src) {
            return <div {...layerAttributes} />;
        }

        const maskImage = combinedLayerProps.properties.maskImage;

        const background = maskImage ? layerAttributes.style.background : undefined;

        if (maskImage) {
            delete layerAttributes.style.background;
            delete layerAttributes.style.backgroundColor;
        }

        /**
         * If there is no transform scale, then add a scale(1.005) to the transform so the mask is slightly bigger than the image.
         */
        const transform = (() => {
            if (!maskImage) return layerAttributes.style.transform;

            let transform = layerAttributes.style.transform || '';

            // Check if 'scale' is already present
            if (!RegexHelpers.validate('transformScale', transform)) {
                // If not, add 'scale(1.005)'
                transform += ' scale(1.005)';
            }

            transform.trim();
            return transform;
        })();

        return (
            <>
                <img {...layerAttributes} data-cy={`${dataCyPrefix}-${layer.type}-${layer.key}-image`} src={src} alt={layerAttributes.style.alt ?? null} />
                {maskImage && !layerPreview && (
                    <div
                        {...layerAttributes}
                        style={{
                            ...layerAttributes.style,
                            transform,
                            background,
                            position: 'absolute',
                            maskImage: `url${src}`,
                            WebkitMaskImage: `url(${src})`,
                            maskSize: layerAttributes.style.objectFit,
                            WebkitMaskSize: layerAttributes.style.objectFit,
                            maskPosition: layerAttributes.style.objectPosition,
                            WebkitMaskPosition: layerAttributes.style.objectPosition,
                            maskRepeat: 'no-repeat',
                            WebkitMaskRepeat: 'no-repeat'
                        }}
                    />
                )}
            </>
        );
    }

    if (layer.type === 'video') {
        const src = (() => {
            // If the video is trimmer or cropped, always get the original.
            if (layerAttributes.style.isCropped) {
                return layerAttributes.style.src;
            }

            // If there is a preview src, use that for performace reasons.
            if (layerAttributes.style.previewSrc) {
                return layerAttributes.style.previewSrc;
            }

            // Otherwise get the original.
            return layerAttributes.style.src;
        })();
        const loop = layerAttributes.style.loop;
        const hideWhenFinished = layerAttributes.style.hideWhenFinished;
        const volume = layerAttributes.style.volume;

        // If there is no src, use a normal div to avoid the broken video icon.
        if (!src) {
            return <div {...layerAttributes} />;
        }

        const id = (() => {
            if (format) {
                return `${format.key}_${layer.key}`;
            }

            return layer.key;
        })();

        return (
            <video
                {...layerAttributes}
                data-cy={`${dataCyPrefix}-${layer.type}${layer.key}-video`}
                key={src}
                crossOrigin="anonymous"
                id={id}
                data-visibility={combinedLayerProps.visibility}
                data-volume={volume}
                data-hide-when-finished={hideWhenFinished}
                data-format-key={format?.key}
                preload="auto"
                src={src}
                loop={loop}
                disableRemotePlayback
                onLoadedData={() => {
                    const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);
                    const currentTime = SceneHelpers.getSceneTime(frameDuration);
                    TimelineHelpers.seekTo(currentTime);
                }}
            />
        );
    }

    if (layer.type === 'audio') {
        const src = layerAttributes.style.src;
        const loop = layerAttributes.style.loop;
        const volume = layerAttributes.style.volume;

        const id = (() => {
            if (format) {
                return `${format.key}_${layer.key}`;
            }

            return layer.key;
        })();

        return (
            <audio
                {...layerAttributes}
                data-cy={`${dataCyPrefix}-${layer.type}${layer.key}-audio`}
                key={src}
                id={id}
                data-format-key={format?.key}
                data-volume={volume}
                data-visibility={combinedLayerProps.visibility}
                preload="auto"
                src={src}
                loop={loop}
                onLoadedMetadata={() => {
                    const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);
                    const currentTime = SceneHelpers.getSceneTime(frameDuration);
                    TimelineHelpers.seekTo(currentTime);
                }}
            />
        );
    }

    if (layer.type === 'lottie') {
        const name = (() => {
            if (format) {
                return `${layerAttributes.key} ${format.key}`;
            }

            return layerAttributes.key;
        })();

        const id = (() => {
            if (format) {
                return `${format.key}_${layer.key}`;
            }

            return layer.key;
        })();

        return (
            <LottieWrapper
                layerAttributes={layerAttributes}
                lottieAnimation={combinedLayerProps.properties.lottieAnimation}
                visibility={combinedLayerProps.visibility || [0, 1]}
                name={name}
                id={id}
            />
        );
    }

    const newLayerChildren = (() => {
        if (layersShouldReverse) {
            return cloneDeep(layer.children).reverse();
        }

        return layer.children;
    })();

    return (
        <div {...layerAttributes}>
            {newLayerChildren.map((child, i) => (
                <Layer {...props} key={child.key} layer={child} totalLayers={newLayerChildren.length} currentLayerIndex={i} />
            ))}
        </div>
    );
});

Layer.displayName = 'Layer';

export default Layer;
