import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useComponentSize } from 'hooks/useComponentSize';
import LayerType from 'components/template-designer/types/layer.type';
import { DesignerSettings, View } from 'components/template-designer/types/template.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { TemplateVersionHelpers } from 'components/template-designer/helpers/template-version.helpers';
import { PREVIEW_LAYER_DARK_BACKGROUND_KEY } from 'components/template-designer/constants';
import ViewState from 'components/data/ViewState';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Layer from './layer';
import '../styles/preview-layer.scss';

interface Props {
    className?: string;
    layer: LayerType;
    frameType: View['frameType'];
    size: {
        width: number;
        height: number;
    };
    includeBackground?: boolean;
    padding?: 'small' | 'large';
}

const PADDING_LARGE = 16;
const PADDING_SMALL = 4;

const PreviewLayer = ({ className, layer, frameType, size, includeBackground, padding = 'large' }: Props) => {
    const { customCSS } = useComponentStore<{ customCSS: DesignerSettings['customCSS'] }>('TemplateDesigner', {
        fields: {
            customCSS: 'designerSettings.customCSS'
        }
    });

    // Get the prefered background for the preview layer.
    const [darkPreview, setDarkPreview] = useState<boolean>(
        (() => {
            const preferedBackgrounds = ViewState.get('templateDesigner', PREVIEW_LAYER_DARK_BACKGROUND_KEY);
            if (!preferedBackgrounds || !preferedBackgrounds[layer.key]) return false;
            return preferedBackgrounds[layer.key] ?? false;
        })()
    );

    const childRef = useRef<HTMLDivElement>(null);
    const layersShouldNotReverse = useMemo(() => TemplateVersionHelpers.layersShouldNotReverse(), []);
    const { width, height } = useComponentSize(childRef);

    /**
     * Calculate scale based on the size of the layer and the size of the container.
     */
    const scale = (() => {
        if (width === 0 || height === 0) return 0;
        const PADDING = padding === 'large' ? PADDING_LARGE : PADDING_SMALL;
        const calculatedPadding = includeBackground ? PADDING : 0;
        const scaleWidth = (size.width - 2 * calculatedPadding) / width;
        const scaleHeight = (size.height - 2 * calculatedPadding) / height;
        return Math.min(scaleWidth, scaleHeight);
    })();

    /**
     * Handle the prefered background for the preview layer.
     * @param isDark - If the background should be dark.
     */
    const handlePreferedBackground = (isDark: boolean) => {
        const darkBackgroundPreviewLayer = ViewState.get('templateDesigner', PREVIEW_LAYER_DARK_BACKGROUND_KEY);
        const newDarkBackgroundPreviewLayer = cloneDeep(darkBackgroundPreviewLayer) ?? {};

        if (isDark) {
            newDarkBackgroundPreviewLayer[layer.key] = isDark;
        } else {
            delete newDarkBackgroundPreviewLayer[layer.key];
        }

        setDarkPreview(isDark);
        ViewState.set('templateDesigner', PREVIEW_LAYER_DARK_BACKGROUND_KEY, newDarkBackgroundPreviewLayer);
    };

    return (
        <div
            className={classNames(
                'template-designer__preview-layer',
                {
                    'template-designer__preview-layer--background': includeBackground && !darkPreview,
                    'template-designer__preview-layer--background-dark': includeBackground && darkPreview
                },
                className
            )}
            style={{ width: size.width, height: size.height }}
            onClick={() => handlePreferedBackground(!darkPreview)}>
            <div
                className="template-designer__preview-layer__container"
                ref={childRef}
                style={{
                    transform: `scale(${scale}) translateX(-50%) translateY(-50%)`
                }}>
                <Layer
                    key={layer.key}
                    frameType={frameType}
                    layer={layer}
                    customCSS={customCSS}
                    totalLayers={1}
                    currentLayerIndex={0}
                    preview
                    layerPreview
                    customJS={false}
                    layersShouldReverse={!layersShouldNotReverse}
                />
            </div>
        </div>
    );
};

export { PreviewLayer };
