import { useEffect, useRef, useState } from 'react';
import Moveable, {
    AbleRequestParam,
    OnDrag,
    OnDragEnd,
    OnDragStart,
    OnResize,
    OnResizeEnd,
    OnResizeStart,
    OnRotate,
    OnRotateEnd,
    OnRoundStart,
    Requester
} from 'react-moveable';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { KeyboardShortcutScope, KeyboardShortcutScopes } from 'hooks/useKeyboardShortcuts';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import LayerProperties, {
    HorizontalAlignOptions,
    MeasurePointOptions,
    PositionOptions,
    VerticalAlignOptions
} from 'components/template-designer/types/layerProperties.type';
import { animationOptions } from 'components/template-designer/config/animationOptions';
import FrameTypeHelpers from 'components/template-designer/helpers/frame-type.helpers';
import { LayerCalculationHelpers } from 'components/template-designer/helpers/layer-calculation.helpers';
import Animation, { AnimationOptions } from 'components/template-designer/types/animation.type';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import { CopyPasteHelpers } from 'components/template-designer/helpers/copy-paste.helpers';
import Template, { State } from 'components/template-designer/types/template.type';
import Format from 'components/template-designer/types/format.type';
import LayerType from 'components/template-designer/types/layer.type';
import { Guideline } from 'components/template-designer/types/formatProperties.type';
import { LayoutGridHelpers } from 'components/template-designer/helpers/layout-grid.helpers';
import { CanvasHelpers } from 'components/template-designer/helpers/canvas.helpers';
import { TimelineHelpers } from 'components/template-designer/helpers/timeline.helpers';
import canvasActive from '../utils/canvasActive';
import getXYProp from '../utils/getXYProp';
import updateXY from '../utils/updateXY';
import getDragDirection from '../utils/getDragDirection';
import { calculateEditorValueBasedOnUnit } from '../utils/calculateValueBasedOnUnit';
import { NewPosition } from '../types/types';
import { DragLayerPosition, calculateDragLayerPosition } from '../utils/calculateDragLayerPosition';
import { LayerTransform } from '../utils/calculatePosResize';
import { convertTransformOrigin, convertDragDirectionToScaleCorners } from '../utils/convertTransformOrigin';
import { snapToGuidelines } from '../utils/snapToGuidelines';
import {
    calculateLayerPosNorthEast,
    calculateLayerPosNorthWest,
    calculateLayerPosSouthEast,
    calculateLayerPosSouthWest
} from '../utils/calculateLayerMeasurePoint';

const SHIFT_KEY_AMOUNT = 10;
const THROTTLE_DEGREES = 45;

interface Props {
    layers: Template['layers'];
    format: Format;
    zoomLevel: number;
    currentTime: number;
    selectedFormats: State['selectedFormats'];
    layer: LayerType;
    layoutGrid: Guideline[] | undefined;
    layerProperties: LayerProperties;
    saveLayer: (
        position: NewPosition['position'],
        positionChanged: NewPosition['positionChanged'],
        widthChanged: NewPosition['widthChanged'],
        heightChanged: NewPosition['heightChanged'],
        rotationChanged: NewPosition['rotationChanged'],
        format?: Format
    ) => void;
}

const useEditor = ({ layerProperties, layers, layer, format, selectedFormats, zoomLevel, currentTime, saveLayer, layoutGrid }: Props) => {
    const [dragTarget, setDragTarget] = useState<HTMLElement | null>(null);
    const [initialTargetRect, setInitialTargetRect] = useState<DOMRect | null>(null);

    const frameDuration = FrameTypeHelpers.getFrameDuration();

    let keepRatio = layerProperties?.properties?.lockedSize ?? false;

    const { x, y, rotation, width, height, measurePoint } = layerProperties.properties;
    const isAnimated =
        layerProperties.animations &&
        Object.keys(layerProperties.animations).some(
            (animationKey) => layerProperties.animations && layerProperties.animations[animationKey] && layerProperties.animations[animationKey].length > 0
        );

    const layerPos = (() => {
        if (!layerProperties.properties.position) return PositionOptions.Absolute;
        if (layerProperties.properties.position === PositionOptions.Static) return PositionOptions.Absolute;
        return layerProperties.properties.position;
    })();

    const xUnit = x.unit;
    const yUnit = y.unit;
    const widthUnit = width.unit;
    const heightUnit = height.unit;

    const [requester, setRequester] = useState<Requester<AbleRequestParam>>();
    const [altKeyPressed, setAltKeyPressed] = useState(false);
    const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

    keepRatio = shiftKeyPressed || keepRatio;

    const moveableRef = useRef<Moveable>(null);
    const targetLayer = document.querySelector<HTMLElement>(`.layers-container__layer.${layer.key}.${format.key}`);
    const containerLayer = targetLayer && (targetLayer.parentNode as HTMLElement);

    const formatRect = document.querySelector(`.template-designer__format__format-style.${format.key}`)?.getBoundingClientRect();
    const containerRect = containerLayer?.getBoundingClientRect();
    // these are the values used for snapping to the guidelines
    const container = {
        left: ((containerRect?.left || 0) - (formatRect?.left || 0)) * (1 / zoomLevel),
        top: ((containerRect?.top || 0) - (formatRect?.top || 0)) * (1 / zoomLevel),
        right: -((containerRect?.right || 0) - (formatRect?.right || 0)) * (1 / zoomLevel),
        bottom: -((containerRect?.bottom || 0) - (formatRect?.bottom || 0)) * (1 / zoomLevel),
        width: (containerRect?.width || 0) * (1 / zoomLevel),
        height: (containerRect?.height || 0) * (1 / zoomLevel)
    };

    const layerInitialPosition: LayerProperties = cloneDeep(layerProperties);

    // get the container width and height
    const containerSize = LayerCalculationHelpers.getContainerSize(layer.key, format.key);
    const containerWidth = containerSize.width;
    const containerHeight = containerSize.height;

    const initialWidth = LayerCalculationHelpers.getLayerSize('width', layerInitialPosition, containerWidth, targetLayer);
    const initialHeight = LayerCalculationHelpers.getLayerSize('height', layerInitialPosition, containerHeight, targetLayer);

    const { translateY, translateX } = (() => {
        const transformValues = {};

        const transformRegex = /(\w+)\(([^)]+)\)/g;
        let match;
        while ((match = transformRegex.exec(targetLayer?.style.transform || '')) !== null) {
            const [, name, value] = match;
            transformValues[name] = value;
        }

        const translateX = (() => {
            let value = '';
            if (transformValues['translateX']) value = transformValues['translateX'];
            if (transformValues['translate']) value = transformValues['translate'].split(', ')[0];
            if (value === undefined) value = '';

            const translateX = parseFloat(value.replace('px', '').replace('%', '').trim()) || 0;
            const translateXUnit = value.match(/([a-zA-Z%]+)/)?.[0];

            if (translateXUnit === '%') {
                return (translateX / 100) * containerHeight - (translateX / 100) * initialWidth;
            }

            return translateX;
        })();

        const translateY = (() => {
            let value = '';
            if (transformValues['translateY']) value = transformValues['translateY'];
            if (transformValues['translate']) value = transformValues['translate'].split(', ')[1];
            if (value === undefined) value = '';

            const translateY = parseFloat(value.replace('px', '').replace('%', '').trim()) || 0;
            const translateYUnit = value.match(/([a-zA-Z%]+)/)?.[0];

            if (translateYUnit === '%') {
                return (translateY / 100) * containerHeight - (translateY / 100) * initialWidth;
            }

            return translateY;
        })();

        return { translateX, translateY };
    })();

    const endPosition = useRef<Omit<NewPosition, 'resizeDirection' | 'usingKeys'>>({
        position: {
            x: x.value,
            xUnit: x.unit,
            y: y.value,
            yUnit: y.unit,
            rotation,
            width: width.value,
            height: height.value,
            widthUnit: width.unit,
            heightUnit: height.unit,
            position: layerPos
        },
        positionChanged: false,
        widthChanged: false,
        heightChanged: false,
        rotationChanged: false
    });

    useEffect(() => {
        setDragTarget(document.querySelector<HTMLElement>(`.drag-target.${layer.key}.${format.key}`));
        showSelectedLayerOutline(true);
        setShowControls(
            shouldShowControls(+layerProperties.properties.width.value, +layerProperties.properties.height.value, layerProperties.properties.display)
        );
    }, [layer]);

    useEffect(() => {
        if (layer.locked) return;
        // Handle the arrow keys to move the layer by clicking on an arrow key.
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [requester]);

    const handleKeyDown = (event: globalThis.KeyboardEvent): void => {
        handleArrowKeys(event);

        if (event.key === 'Alt' && !altKeyPressed) {
            event.preventDefault();
            setAltKeyPressed(true);
        }

        if (event.key === 'Shift' && !shiftKeyPressed) {
            event.preventDefault();
            setShiftKeyPressed(true);
        }
    };

    const handleKeyUp = (event: globalThis.KeyboardEvent): void => {
        if (event.key === 'Alt') {
            setAltKeyPressed(false);
        }

        if (event.key === 'Shift') {
            setShiftKeyPressed(false);
        }
    };

    /**
     * Handles arrow keys. Asks Moveable to run a drag event when pressing on an arrow key.
     * @param event - Event from keydown.
     */
    const handleArrowKeys = (event: globalThis.KeyboardEvent): void => {
        const keyboardShortcutScope = KeyboardShortcutScope.getScope();
        if (keyboardShortcutScope !== KeyboardShortcutScopes.TDEditor) return;
        if (layerProperties.properties.rotation && layerProperties.properties.rotation !== 0) return;
        if (!canvasActive()) return;
        if (document.activeElement?.tagName.toLowerCase() === 'li') return;
        if (!['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(event.key)) return;
        if (!moveableRef.current?.moveable?.request) return;

        event.preventDefault();
        event.stopPropagation();

        const { key, shiftKey } = event;
        const ctrlKey = event.ctrlKey || event.metaKey;

        // Request a draggable event when clicking an arrow key.
        let finalRequester;

        if (moveableRef.current?.moveable) {
            if (ctrlKey) {
                finalRequester = moveableRef.current.moveable.request('resizable');
            } else {
                finalRequester = moveableRef.current.moveable.request('draggable');
            }

            setRequester(finalRequester);
        }

        if (!finalRequester) return;

        // Move 10px when shift key is pressed.
        const amount = shiftKey ? SHIFT_KEY_AMOUNT : 1;

        if (ctrlKey) {
            switch (key) {
                case 'ArrowUp':
                    finalRequester.request({ deltaHeight: -amount });
                    break;
                case 'ArrowDown':
                    finalRequester.request({ deltaHeight: amount });
                    break;
                case 'ArrowLeft':
                    finalRequester.request({ deltaWidth: -amount });
                    break;
                case 'ArrowRight':
                    finalRequester.request({ deltaWidth: amount });
                    break;
                default:
                    break;
            }
        } else {
            switch (key) {
                case 'ArrowUp':
                    finalRequester.request({ deltaY: -amount });
                    break;
                case 'ArrowDown':
                    finalRequester.request({ deltaY: amount });
                    break;
                case 'ArrowLeft':
                    finalRequester.request({ deltaX: -amount });
                    break;
                case 'ArrowRight':
                    finalRequester.request({ deltaX: amount });
                    break;
                default:
                    break;
            }
        }

        finalRequester.requestEnd();
        setRequester(undefined);
    };

    /**
     * Checks if the editor should be disabled.
     */
    const shouldShowControls = (width: number, height: number, visible: boolean) => {
        if (visible === false) return false;
        if (width < 10 || height < 10) return false;
        return true;
    };

    /**
     * Sets the opacity on the selected layer editor.
     * @param opacity - The opacity to set on the selected layer editor.
     */
    const showSelectedLayerOutline = (show: boolean) => {
        const editorSelectedLayer = document.querySelector<HTMLElement>(`.template-designer__editor__selected-layer.${format.key}`);
        if (!editorSelectedLayer) return;
        if (show) {
            editorSelectedLayer.style.opacity = '1';
        } else {
            editorSelectedLayer.style.opacity = '0';
        }
    };

    /**
     * Stores the new position in a reference so it can be used to save the new position to the store.
     * @param newPosition - New position with: x, y, width, height, rotation.
     * @param positionChanged - Indicates if the position is changed.
     * @param sizeChanged - Indicates if the size is changed.
     * @param rotationChanged - Indicates if the rotation is changed.
     */
    const storeNewPosition = (
        newPosition: NewPosition['position'],
        positionChanged: boolean,
        widthChanged: boolean,
        heightChanged: boolean,
        rotationChanged: boolean
    ): void => {
        endPosition.current.position = newPosition;
        endPosition.current.positionChanged = positionChanged;
        endPosition.current.widthChanged = widthChanged;
        endPosition.current.heightChanged = heightChanged;
        endPosition.current.rotationChanged = rotationChanged;
    };

    /**
     * Gets called by any end event above. Saves the end position to the store.
     */
    const saveNewPosition = () => {
        saveLayer(
            endPosition.current.position,
            endPosition.current.positionChanged,
            endPosition.current.widthChanged,
            endPosition.current.heightChanged,
            endPosition.current.rotationChanged,
            format
        );
    };

    /**
     * Resets the moveable editor.
     */
    const resetMoveable = () => {
        setShowControls(shouldShowControls(+endPosition.current.position.width, +endPosition.current.position.height, layerProperties.properties.display));
        showSelectedLayerOutline(true);
        setDragLayer(calculateDragLayerPosition(format.key, targetLayer, zoomLevel, rotation, measurePoint));
        setShowDragLayer(true);
        LayoutGridHelpers.clearTemporaryGuides();
    };

    /**
     * Function to calculate distance between two angles in degrees (considering a 360-degree circle)
     * @param angle1Degrees - The first angle in degrees
     * @param angle2Degrees - The second angle in degrees
     * @returns - The distance between the two angles in degrees
     */
    const angleDistance = (angle1Degrees: number, angle2Degrees: number): number => {
        // Calculate the absolute difference between the angles
        let difference = Math.abs(angle1Degrees - angle2Degrees);

        // Ensure the difference is within the range [0, 360]
        difference = difference % 360;

        // Ensure the difference is the smallest angle (less than 180)
        if (difference > 180) {
            difference = 360 - difference;
        }

        return difference;
    };

    /**
     * Function to constrain the movement of a layer to the X or Y axis or a diagonal axis.
     * @param oldX  The old X position of the layer
     * @param oldY  The old Y position of the layer
     * @param newX  The new X position of the layer
     * @param newY  The new Y position of the layer
     * @returns The new X and Y position of the layer constrained to the X, Y, or diagonal axis
     */
    const constrainAxis = (oldX: number, oldY: number, newX: number, newY: number) => {
        const deltaX = newX - oldX;
        const deltaY = newY - oldY;

        // Calculate the angle between the old and new coordinates
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

        let correctedX = newX;
        let correctedY = newY;

        const distXAxis = Math.min(angleDistance(angle, 0), angleDistance(angle, 360), angleDistance(angle, 180));
        const distYAxis = Math.min(angleDistance(angle, 90), angleDistance(angle, 270));
        const distYDiagonal = Math.min(angleDistance(angle, 135), angleDistance(angle, 315));
        const distXDiagonal = Math.min(angleDistance(angle, 45), angleDistance(angle, 225));

        const smallestDegrees = Math.min(distXAxis, distYAxis, distXDiagonal, distYDiagonal);
        // Check the angle to determine which axis to snap to
        if (distXAxis === smallestDegrees) {
            // Snap to the X-axis
            correctedY = oldY;
        } else if (distYAxis === smallestDegrees) {
            // Snap to the Y-axis
            correctedX = oldX;
        } else if (distXDiagonal === smallestDegrees) {
            const avgChange = (deltaX + deltaY) / 2;
            // Snap to the X-diagonal-axis
            correctedX = oldX + avgChange;
            correctedY = oldY + avgChange;
        } else if (distYDiagonal === smallestDegrees) {
            const avgChange = (deltaX - deltaY) / 2;
            // Snap to the Y-diagonal-axis
            correctedX = oldX + avgChange;
            correctedY = oldY - avgChange;
        }

        return { constrainedX: correctedX, constrainedY: correctedY };
    };

    /**
     * Function that draws a line from the original position to the new position of the layer to show the axis of drag.
     * @param left The left position of the layer
     * @param top The top position of the layer
     * @param xOriginal The original x position of the layer
     * @param yOriginal The original y position of the layer
     */
    const drawGuideline = (): void => {
        const canvas: HTMLCanvasElement = document.querySelector(`.template-designer__editor__shift-drag-guide.${format.key}`) as HTMLCanvasElement;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;

        const canvasSize = CanvasHelpers.getMaxCanvasSize(format.width, format.height);
        canvas.width = canvasSize.width;
        canvas.height = canvasSize.height;

        context.clearRect(0, 0, canvasSize.width, canvasSize.height);

        const DASHED_LINE_WIDTH = 15 * canvasSize.sizeCorrection;
        const correctedLineWidth = CanvasHelpers.scaleValueWithZoom(DASHED_LINE_WIDTH);

        // Starting point of the line
        const formatLayerRect = document.querySelector<HTMLElement>(`.template-designer__format #${format.key}`)?.getBoundingClientRect();
        const targetLayer = document.querySelector<HTMLElement>(`.layers-container__layer.${layer.key}.${format.key}`)?.getBoundingClientRect();

        if (targetLayer && formatLayerRect && containerRect && initialTargetRect) {
            const offsetX = targetLayer.width / 2;
            const offsetY = targetLayer.height / 2;
            // Ending point of the line
            const startX = (initialTargetRect.left - formatLayerRect.left + offsetX) / zoomLevel;
            const startY = (initialTargetRect.top - formatLayerRect.top + offsetY) / zoomLevel;
            const endX = (targetLayer.left - formatLayerRect.left + offsetX) / zoomLevel;
            const endY = (targetLayer.top - formatLayerRect.top + offsetY) / zoomLevel;

            // Draw the line
            context.beginPath();
            context.setLineDash([15 * canvasSize.scaleCorrection, 5 * canvasSize.scaleCorrection]);
            context.strokeStyle = '#7CACFF'; // Set line color
            context.lineWidth = correctedLineWidth; // Set guideline width
            context.moveTo(startX * canvasSize.scaleCorrection, startY * canvasSize.scaleCorrection); // Move to the starting point
            context.lineTo(endX * canvasSize.scaleCorrection, endY * canvasSize.scaleCorrection); // Draw a line to the ending point
            context.stroke();
        }
    };

    /**
     * Gets called by Moveable when the user begins to drag the layer.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onDragLayerStart = ({ inputEvent, set }: OnDragStart): void => {
        set([0, 0]);
        const initialTargetRect = document.querySelector<HTMLElement>(`.drag-target.${layer.key}.${format.key}`);
        if (initialTargetRect) {
            setInitialTargetRect(initialTargetRect.getBoundingClientRect().toJSON());
        }

        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();
    };

    /**
     * Gets called by Moveable when the user drags a layer. Calculates the new drag position.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onDragLayer = ({ target, beforeDist: dist, inputEvent }: OnDrag): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        showSelectedLayerOutline(false);
        setShowControls(false);
        setShowDragLayer(false);

        if (layerProperties.properties.position === PositionOptions.Static) {
            targetLayer?.style.setProperty('position', layerPos);
        }

        if (
            (layerInitialPosition.properties.verticalAlign !== null && layerInitialPosition.properties.verticalAlign !== undefined) ||
            (layerInitialPosition.properties.horizontalAlign !== null && layerInitialPosition.properties.horizontalAlign !== undefined)
        ) {
            // Turn off quick align
            let transform = targetLayer?.style.transform;
            // Remove translate
            const translate = /translate\((-?\d+(?:\.\d*)?)px, (-?\d+(?:\.\d*)?)px\)/;
            const translateX = /translateX\((.*)%\)/;
            const translateY = /translateY\((.*)%\)/;
            const matrix = /matrix\(.*?\)/g;

            transform = transform?.replace(translate, '').replace(translateX, '').replace(translateY, '').replace(matrix, '');

            if (targetLayer) {
                if (transform) targetLayer.style.transform = transform;
                targetLayer.style.top = 'initial';
                targetLayer.style.left = 'initial';
            }
        }

        // We calculate the new top and left position ourself instead of what we get back from Moveable.
        // That way we can include the measure point in the calculation.
        let top = (() => {
            const y = typeof layerInitialPosition.properties.y.value === 'string' ? 0 : layerInitialPosition.properties.y.value;

            if (measurePoint === 'se' || measurePoint === 'sw') {
                if (layerInitialPosition.properties.verticalAlign === VerticalAlignOptions.Middle) {
                    return (50 / 100) * containerHeight - initialHeight / 2 + -dist[1] / zoomLevel;
                }

                if (layerInitialPosition.properties.verticalAlign === VerticalAlignOptions.Bottom) {
                    // we use a harcoded 100% for the Y position to support older templates. older templates still have 0 px set in as Y position whichs breaks the dragging
                    return (100 / 100) * containerHeight - containerHeight + -dist[1] / zoomLevel;
                }

                if (layerInitialPosition.properties.verticalAlign === VerticalAlignOptions.Top) {
                    // we use a harcoded 100% for the Y position to support older templates. older templates still have 0 px set in as Y position whichs breaks the dragging
                    return (100 / 100) * containerHeight - initialHeight + -dist[1] / zoomLevel;
                }

                if (yUnit === '%' && !layerInitialPosition.properties.verticalAlign) {
                    // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
                    // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
                    // so we can drag layers without jumping in the middle of an animation or with multiple animations set
                    return (y / 100) * containerHeight + -dist[1] / zoomLevel + translateY;
                }

                // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
                // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
                // so we can drag layers without jumping in the middle of an animation or with multiple animations set
                return y + -dist[1] / zoomLevel + translateY;
            }

            if (layerInitialPosition.properties.verticalAlign === VerticalAlignOptions.Middle) {
                // we use a hardcoded 50% here because this value is always set when vertical align middle is set
                // this is because we need to know the middle of the layer to calculate the correct position
                // This supports older templates as well where they are still using pxs with quick align in layer properties
                return (50 / 100) * containerHeight - initialHeight / 2 + dist[1] / zoomLevel;
            }

            if (layerInitialPosition.properties.verticalAlign === VerticalAlignOptions.Bottom) {
                // we use a harcoded 100% for the Y position to support older templates. older templates still have px set in as Y position whichs breaks the dragging
                return (100 / 100) * containerHeight - initialHeight + dist[1] / zoomLevel;
            }

            if (layerInitialPosition.properties.verticalAlign === VerticalAlignOptions.Top) {
                // we use a harcoded 0% for the Y position to support older templates. older templates still have px set in as Y position whichs breaks the dragging
                return (0 / 100) * containerHeight + dist[1] / zoomLevel;
            }

            if (yUnit === '%' && !layerInitialPosition.properties.verticalAlign) {
                // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
                // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
                // so we can drag layers without jumping in the middle of an animation or with multiple animations set
                return (y / 100) * containerHeight + dist[1] / zoomLevel + translateY;
            }

            // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
            // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
            // so we can drag layers without jumping in the middle of an animation or with multiple animations set
            return y + dist[1] / zoomLevel + translateY;
        })();

        top = Math.round(top);

        let left = (() => {
            const x = typeof layerInitialPosition.properties.x.value === 'string' ? 0 : layerInitialPosition.properties.x.value;
            if (measurePoint === 'ne' || measurePoint === 'se') {
                if (layerInitialPosition.properties.horizontalAlign === HorizontalAlignOptions.Center) {
                    return (50 / 100) * containerWidth - initialWidth / 2 + -dist[0] / zoomLevel;
                }

                if (layerInitialPosition.properties.horizontalAlign === HorizontalAlignOptions.Right) {
                    // we use a harcoded 100% for the X position to support older templates. older templates still have px set in as X position whichs breaks the dragging
                    return (0 / 100) * containerWidth + -dist[0] / zoomLevel;
                }

                if (layerInitialPosition.properties.horizontalAlign === HorizontalAlignOptions.Left) {
                    // we use a harcoded 0% for the X position to support older templates. older templates still have px set in as X position whichs breaks the dragging
                    return (0 / 100) * containerWidth + containerWidth - initialWidth + -dist[0] / zoomLevel;
                }

                if (xUnit === '%' && !layerInitialPosition.properties.horizontalAlign) {
                    // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
                    // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
                    // so we can drag layers without jumping in the middle of an animation or with multiple animations set
                    return (x / 100) * containerWidth + -dist[0] / zoomLevel + translateX;
                }

                // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
                // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
                // so we can drag layers without jumping in the middle of an animation or with multiple animations set
                return x + -dist[0] / zoomLevel + translateX;
            }

            if (layerInitialPosition.properties.horizontalAlign === HorizontalAlignOptions.Center) {
                // we use a hardcoded 50% here because this value is always set when horizontal align center is set
                // this is because we need to know the center of the layer to calculate the correct position
                // This supports older templates as well where they are still using pxs with quick align in layer properties
                return (50 / 100) * containerWidth - initialWidth / 2 + dist[0] / zoomLevel;
            }

            if (layerInitialPosition.properties.horizontalAlign === HorizontalAlignOptions.Right) {
                // we use a harcoded 100% for the X position to support older templates. older templates still have px set in as X position whichs breaks the dragging
                return (100 / 100) * containerWidth - initialWidth + dist[0] / zoomLevel;
            }

            if (layerInitialPosition.properties.horizontalAlign === HorizontalAlignOptions.Left) {
                // we use a harcoded 0% for the X position to support older templates. older templates still have px set in as X position whichs breaks the dragging
                return (0 / 100) * containerWidth + dist[0] / zoomLevel;
            }

            if (xUnit === '%' && !layerInitialPosition.properties.horizontalAlign) {
                // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
                // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
                // so we can drag layers without jumping in the middle of an animation or with multiple animations set
                return (x / 100) * containerWidth + dist[0] / zoomLevel + translateX;
            }

            // we have to add the translate x value on the x position because this is coming from the animations and is calculated by scenejs
            // thats we cannot use the value in the animations object. We get it from the layer itself with calculated translate
            // so we can drag layers without jumping in the middle of an animation or with multiple animations set
            return x + dist[0] / zoomLevel + translateX;
        })();

        left = Math.round(left);

        const newWidth = typeof width.value === 'string' ? 0 : width.value;
        const newHeight = typeof height.value === 'string' ? 0 : height.value;

        const animations = layerProperties.animations ?? {};
        const hasPositionAnimation = animations.position && animations.position.length > 0;
        const customAnimationOptions = animationOptions.map((animationOption) => animationOption.key);
        const predefinedAnimations = Object.keys(animations).filter((animationKey) => !customAnimationOptions.includes(animationKey as AnimationOptions));

        const currentStamp = Math.round((currentTime / frameDuration) * 100) / 100;

        const listKeyframes: Animation[] = [];
        for (const predefinedAnimationkey of predefinedAnimations) {
            const keyframes = animations[predefinedAnimationkey] || [];

            const filteredKeyframes = keyframes.filter((keyframe) => keyframe.stamp >= currentStamp);

            listKeyframes.push(...filteredKeyframes);
        }

        function findClosestKeyframe(listKeyframes: Animation[], currentStamp: number): Animation | undefined {
            let closestKeyframe: Animation | undefined;
            let closestDistance = Infinity;

            for (const keyframe of listKeyframes) {
                const distance = Math.abs(keyframe.stamp - currentStamp);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    closestKeyframe = keyframe;
                }
            }

            return closestKeyframe;
        }

        // we are searching for the closest keyframe to the right of the current stamp
        // we are using the closest keyframe to determine the direction of the predefined animation
        // based on the direction we need to do different calculations
        const closestKeyframe = findClosestKeyframe(listKeyframes, currentStamp) as any;

        if (shiftKeyPressed) {
            // calculate the original X position based on the unit
            // we always need to add the translate X value on the X position because we are always setting the left,top,right and bottom values
            // when there are position keyframes we are retracting the X value in the translate object
            const xOriginal = (() => {
                const initialX = typeof x.value === 'string' ? 0 : x.value;

                if (xUnit === '%') return (initialX / 100) * containerWidth + translateX;
                return initialX + translateX;
            })();

            // calculate the original Y position based on the unit
            // we always need to add the translate Y value on the Y position because we are always setting the left,top,right and bottom values
            // when there are position keyframes we are retracting the Y value in the translate object
            const yOriginal = (() => {
                const initialY = typeof y.value === 'string' ? 0 : y.value;

                if (yUnit === '%') return (initialY / 100) * containerHeight + translateY;
                return initialY + translateY;
            })();

            // Constrain the movement to the X or Y axis or a diagonal axis
            const { constrainedX, constrainedY } = constrainAxis(xOriginal, yOriginal, left, top);
            left = constrainedX;
            top = constrainedY;

            drawGuideline();
        }

        let newX = left;
        let newY = top;
        if (closestKeyframe?.value?.position && !hasPositionAnimation) {
            const { position } = closestKeyframe.value;
            const { direction } = position;

            if (direction === 'up' && newY) {
                newY -= translateY;
            }
            if (direction === 'down' && newY) {
                newY += -translateY;
            }
            if (direction === 'left' && newX) {
                newX -= translateX;
            }
            if (direction === 'right' && newX) {
                newX += -translateX;
            }
            if (direction === 'degrees') {
                newY += -translateY;
                newX += -translateX;
            }
        }

        const usingKeys = typeof inputEvent === 'undefined';
        // we only snap to guidelines when rotation is 0, the layer is not animated and the user is not using the keys to move layers
        // if a user is moving layers with keys and it snaps it cannot be moved out of the snapping position so we disable it
        // if a layer is rotated the position we know becomes incorrect as the browser moves the x and y to a different position (visually) so we disable it
        // if a layer is animated we disable it because the position is calculated by scenejs and we dont know it so we disable it (TODO: Check if this is true)
        if (!shiftKeyPressed && !isAnimated && (rotation === 0 || rotation === undefined) && !usingKeys) {
            const targetLayer = document.querySelector<HTMLElement>(`.drag-target.${layer.key}.${format.key}`);
            const targetLayerRect = targetLayer?.getBoundingClientRect();

            const newWidthPx = (() => {
                if (newWidth) {
                    if (width.unit === 'px') {
                        return newWidth;
                    } else {
                        return (newWidth / 100) * containerWidth;
                    }
                }
                return (targetLayerRect?.width || 0) / zoomLevel;
            })();
            const newHeightPx = (() => {
                if (newHeight) {
                    if (height.unit === 'px') {
                        return newHeight;
                    } else {
                        return (newHeight / 100) * containerHeight;
                    }
                }
                return (targetLayerRect?.height || 0) / zoomLevel;
            })();
            let boundingClientRectData;
            if (targetLayer) {
                const originalPosition = {
                    position: targetLayer.style.position,
                    left: targetLayer.style.left,
                    top: targetLayer.style.top
                };

                // Temporarily change the position to absolute with left: 0 and top: 0
                targetLayer.style.position = 'relative';
                targetLayer.style.left = '0';
                targetLayer.style.top = '0';

                // Get the bounding client rect data
                boundingClientRectData = targetLayer.getBoundingClientRect();

                // Restore the original position
                targetLayer.style.position = originalPosition.position;
                targetLayer.style.left = originalPosition.left;
                targetLayer.style.top = originalPosition.top;
            }

            // Absolute position of the target layer if it was 0, 0 position
            const relativeTargetLayerBase = {
                left: Math.round(((boundingClientRectData?.left || 0) - (containerRect?.left || 0)) * (1 / zoomLevel)),
                top: Math.round(((boundingClientRectData?.top || 0) - (containerRect?.top || 0)) * (1 / zoomLevel))
            };

            const neww = snapToGuidelines(
                layoutGrid,
                measurePoint,
                format,
                container,
                relativeTargetLayerBase,
                layerProperties.properties.position,
                newX,
                newY,
                newWidthPx,
                newHeightPx
            );
            left = neww.x;
            top = neww.y;

            newX = Math.round(neww.x);
            newY = Math.round(neww.y);
        } else {
            newX = Math.round(newX);
            newY = Math.round(newY);
        }

        // the calculations is always done in pixels this makes it way easier to calculate the new position
        // however if the user has the x and y unit set to percentage we need to convert it back to percentage
        newX = calculateEditorValueBasedOnUnit(xUnit, newX, containerWidth);
        newY = calculateEditorValueBasedOnUnit(yUnit, newY, containerHeight);

        const position = {
            x: newX,
            y: newY,
            xUnit: xUnit,
            yUnit: yUnit,
            widthUnit,
            heightUnit,
            rotation: Math.round(rotation || 0),
            width: newWidth,
            height: newHeight,
            position: layerPos
        };
        storeNewPosition(position, true, false, false, false);

        // Get the correct x and y prop based on the measure point so we know which style to update.
        const [xProp, yProp] = getXYProp(layerProperties.properties.measurePoint);

        // if quick align is enabled we dont remove the translatex and translatey otherwise the position will shift
        if (
            (layerInitialPosition.properties.verticalAlign !== null && layerInitialPosition.properties.verticalAlign !== undefined) ||
            (layerInitialPosition.properties.horizontalAlign !== null && layerInitialPosition.properties.horizontalAlign !== undefined)
        ) {
            target.style[xProp] = left + 'px';
            target.style[yProp] = top + 'px';
        } else {
            target.style[xProp] = left - translateX + 'px';
            target.style[yProp] = top - translateY + 'px';
        }

        // reset bottom,right,top or left based on measurepoints
        // This needed when a user starts dragging a layer with quick align which can set other values than that is updated while dragging
        switch (measurePoint) {
            case MeasurePointOptions.NW:
                target.style.bottom = 'initial';
                target.style.right = 'initial';
                break;
            case MeasurePointOptions.NE:
                target.style.bottom = 'initial';
                target.style.left = 'initial';
                break;
            case MeasurePointOptions.SE:
                target.style.top = 'initial';
                target.style.left = 'initial';
                break;
            case MeasurePointOptions.SW:
                target.style.top = 'initial';
                target.style.right = 'initial';
                break;
        }

        EventEmitterHelpers.sent(EventEmitterTypes.TDlayerDrag, Math.random());
    };

    /**
     * Gets called by Moveable when the user lets go off the layer. It will save the new position to the store.
     * Copies the layer when, the alt key is pressed.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onDragLayerEnd = ({ lastEvent, inputEvent }: OnDragEnd): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        if (lastEvent && lastEvent.inputEvent && lastEvent.inputEvent.altKey) {
            CopyPasteHelpers.copyLayer(layer.key);
            CopyPasteHelpers.paste();
        }

        lastEvent && saveNewPosition();

        resetMoveable();
    };

    /**
     * Gets called by Moveable when the user begins to resize the layer.
     * Checks if the current layer has a width and height and updates it accordingly.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onResizeLayerStart = ({ inputEvent }: OnResizeStart): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        showSelectedLayerOutline(false);
        setShowControls(true);

        if (!layerInitialPosition.properties.width.value && typeof layerInitialPosition.properties.width.value === 'string' && targetLayer)
            layerInitialPosition.properties.width.value = targetLayer.offsetWidth;
        if (!layerInitialPosition.properties.height.value && typeof layerInitialPosition.properties.height.value === 'string' && targetLayer)
            layerInitialPosition.properties.height.value = targetLayer.offsetHeight;
    };

    /**
     * Gets called by Moveable when the user resizes a layer. Calculates a new size.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onResizeLayer = ({ target, dist, direction, inputEvent }: OnResize): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        const mouseResize = inputEvent?.type === 'mousemove';

        // We are calculating the new dist becaused on if the user pressed shift key or the lock size is enabled
        // moveable has issues with shift + resize and zoomed in canvas so we are using our own calculations here
        const calculatedDist = (() => {
            if (!mouseResize) return dist;
            if (!keepRatio) return dist;

            const aspectRatio = initialWidth / initialHeight;
            let adjustedDistWidth = dist[0] || 0;
            let adjustedDistHeight = dist[1] || 0;
            if (Math.abs(adjustedDistWidth) > Math.abs(adjustedDistHeight)) {
                // Calculate adjustedDistHeight based on adjustedDistWidth and aspectRatio
                adjustedDistHeight = adjustedDistWidth / aspectRatio;
            } else {
                // Calculate adjustedDistWidth based on adjustedDistHeight and aspectRatio
                adjustedDistWidth = adjustedDistHeight * aspectRatio;
            }

            return [adjustedDistWidth, adjustedDistHeight];
        })();

        // calculate the original X position based on the unit
        // we always need to add the translate X value on the X position because we are always setting the left,top,right and bottom values
        // when there are position keyframes we are retracting the X value in the translate object
        const xOriginal = (() => {
            const initialX = typeof x.value === 'string' ? 0 : x.value;

            if (xUnit === '%') return (initialX / 100) * containerWidth + translateX;
            return initialX + translateX;
        })();

        // calculate the original Y position based on the unit
        // we always need to add the translate Y value on the Y position because we are always setting the left,top,right and bottom values
        // when there are position keyframes we are retracting the Y value in the translate object
        const yOriginal = (() => {
            const initialY = typeof y.value === 'string' ? 0 : y.value;
            if (yUnit === '%') return (initialY / 100) * containerHeight + translateY;
            return initialY + translateY;
        })();

        let layerWidth = Math.round(initialWidth + calculatedDist[0] / zoomLevel);

        let layerHeight = Math.round(initialHeight + calculatedDist[1] / zoomLevel);

        const dragDirection = getDragDirection(direction);

        let newX = xOriginal;
        let newY = yOriginal;

        let addX = calculatedDist[0] / zoomLevel;
        if (layerWidth === 0) {
            addX = -initialWidth;
        }

        let addY = calculatedDist[1] / zoomLevel;
        if (layerHeight === 0) {
            addY = -initialHeight;
        }

        const layerTransform: LayerTransform = {
            newHeight: layerHeight,
            newWidth: layerWidth,
            oldHeight: initialHeight,
            oldWidth: initialWidth,
            rotation: rotation || 0,
            rotationAnchor: convertTransformOrigin(
                layerProperties.properties?.transformOrigin?.xOffset || 0,
                layerProperties.properties?.transformOrigin?.yOffset || 0
            ),
            position: {
                x: newX,
                y: newY
            }
        };

        const scaleCorners = convertDragDirectionToScaleCorners(dragDirection);

        // Calculate the new x and y position based on the change in width and height.
        // if the width or height is 0 we cant calculate a new position so we skip it
        if (layerHeight > 0 && layerWidth > 0) {
            // based on the measurepoint we have different calculations per drag direction
            if (measurePoint === 'nw') {
                const { x: calculatedX, y: calculatedY } = calculateLayerPosNorthWest(
                    newX,
                    newY,
                    rotation,
                    addX,
                    addY,
                    dragDirection,
                    scaleCorners,
                    layerTransform
                );
                newX = calculatedX;
                newY = calculatedY;
            } else if (measurePoint === 'ne') {
                const { x, y } = calculateLayerPosNorthEast(newX, newY, rotation, addX, addY, dragDirection, scaleCorners, layerTransform);
                newX = x;
                newY = y;
            } else if (measurePoint === 'se') {
                const { x, y } = calculateLayerPosSouthEast(newX, newY, rotation, addX, addY, dragDirection, scaleCorners, layerTransform);
                newX = x;
                newY = y;
            } else if (measurePoint === 'sw') {
                const { x, y } = calculateLayerPosSouthWest(newX, newY, rotation, addX, addY, dragDirection, scaleCorners, layerTransform);
                newX = x;
                newY = y;
            }
        }

        // the calculations is always done in pixels this makes it way easier to calculate the new position
        // however if the user has the x and y unit set to percentage we need to convert it back to percentage
        newX = calculateEditorValueBasedOnUnit(xUnit, newX, containerWidth);
        newY = calculateEditorValueBasedOnUnit(yUnit, newY, containerHeight);
        layerWidth = calculateEditorValueBasedOnUnit(widthUnit, layerWidth, containerWidth);
        layerHeight = calculateEditorValueBasedOnUnit(heightUnit, layerHeight, containerHeight);

        const position = {
            x: newX,
            y: newY,
            xUnit,
            yUnit,
            widthUnit,
            heightUnit,
            rotation: Math.round(rotation || 0),
            width: layerWidth,
            height: layerHeight,
            position: layerPos
        };

        if (position.width < 0) {
            position.width = 0;
        }

        if (position.height < 0) {
            position.height = 0;
        }

        // Check if the x and y needs to be updated based on the measure point and the direct is resized.
        // if quick align is enabled we dont want to move the position
        // we let quick align position the layer we only make the width and height bigger or smaller
        if (
            (updateXY(measurePoint, direction) || rotation !== 0) &&
            (layerInitialPosition.properties.verticalAlign === null || layerInitialPosition.properties.verticalAlign === undefined) &&
            (layerInitialPosition.properties.horizontalAlign === null || layerInitialPosition.properties.horizontalAlign === undefined)
        ) {
            // if the layer has a position animation we need to remove the translate x and y otherwise the position will shift
            // because it will be added twice to the layer
            if (layerProperties.animations?.position && layerProperties.animations.position.length > 0) {
                // Turn off animation position
                let transform = targetLayer?.style.transform;
                // Remove translate
                const translate = /translate\((-?\d+(?:\.\d*)?)px, (-?\d+(?:\.\d*)?)px\)/;
                const translateX = /translateX\((.*)%\)/;
                const translateY = /translateY\((.*)%\)/;
                const matrix = /matrix\(.*?\)/g;

                transform = transform?.replace(translate, '').replace(translateX, '').replace(translateY, '').replace(matrix, '');

                if (targetLayer) {
                    if (transform) targetLayer.style.transform = transform;
                    targetLayer.style.top = 'initial';
                    targetLayer.style.left = 'initial';
                }
            }

            const [xProp, yProp] = getXYProp(measurePoint);
            target.style[xProp] = `${position.x}${xUnit}`;
            target.style[yProp] = `${position.y}${yUnit}`;

            storeNewPosition(position, true, initialWidth !== position.width, initialHeight !== position.height, false);
        } else {
            storeNewPosition(
                {
                    ...endPosition.current.position,
                    rotation,
                    x: layerProperties.properties.x.value,
                    y: layerProperties.properties.y.value,
                    width: position.width,
                    height: position.height,
                    position: position.position,
                    widthUnit: position.widthUnit,
                    heightUnit: position.heightUnit,
                    xUnit: position.xUnit,
                    yUnit: position.yUnit
                },
                false,
                initialWidth !== position.width,
                initialHeight !== position.height,
                false
            );
        }

        // update the DOM style immediately to prevent flickering and performance issues
        if (initialWidth !== position.width) target.style.width = `${layerWidth}${widthUnit}`;
        if (initialHeight !== position.height) target.style.height = `${layerHeight}${heightUnit}`;

        EventEmitterHelpers.sent(EventEmitterTypes.TDlayerDrag, Math.random());
    };

    /**
     * Gets called by Moveable when the user lets go off the layer. It will save the new size to the store.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onResizeLayerEnd = ({ lastEvent, inputEvent }: OnResizeEnd): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        lastEvent && saveNewPosition();

        resetMoveable();
    };

    /**
     * Gets called by Moveable when the user begins to rotate the layer.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onRotateLayerStart = ({ inputEvent }: OnRoundStart): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        setShowControls(true);
        showSelectedLayerOutline(false);
    };

    /**
     * Calculates the new rotation of the layer based on the mouse position.
     * @param target The target element that is being rotated.
     * @param mouseX The x position of the mouse.
     * @param mouseY The y position of the mouse.
     * @returns The new rotation of the layer.
     */
    const rotateDiv = (target: HTMLElement | SVGElement, mouseX: number, mouseY: number) => {
        const rect = target.getBoundingClientRect();
        const deltaX = mouseX - (rect.left + rect.width / 2);
        const deltaY = mouseY - (rect.top + rect.height / 2);

        // Calculate the angle and add 90 degrees
        let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) + 90;

        if (shiftKeyPressed) {
            // Round the angle to the nearest multiple of THROTTLE_DEGREES
            angle = Math.round(angle / THROTTLE_DEGREES) * THROTTLE_DEGREES;
        }

        // Apply the rotation to the target div
        return angle;
    };

    /**
     * Gets called by Moveable when the user rotates a layer. Calculates new rotation.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onRotateLayer = ({ target, inputEvent }: OnRotate): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();

        // Get the remainder of rotation. Otherwise the rotation value is something like 560 degrees. (More then a single rotation).
        const calculatedRotation = rotateDiv(target, inputEvent.clientX, inputEvent.clientY) % 360;

        const position = {
            x: x.value,
            y: y.value,
            xUnit,
            yUnit,
            rotation: Math.round(calculatedRotation ?? 0),
            width: width.value,
            height: height.value,
            position: layerPos,
            widthUnit,
            heightUnit
        };
        storeNewPosition(position, false, false, false, true);

        // If there is already a rotate value, change it, otherwise add a new rotate.
        const rotate = /(rotate)(\(.*(?:deg\)))/g;
        if (target.style.transform && target.style.transform.match(rotate)) {
            target.style.transform = target.style.transform.replace(rotate, '$1(' + calculatedRotation + 'deg)');
        } else {
            target.style.transform += `rotate(${calculatedRotation}deg)`;
        }

        EventEmitterHelpers.sent(EventEmitterTypes.TDlayerDrag, Math.random());
    };

    /**
     * Gets called by Moveable when the user lets go off the layer. It will save the new rotation to the store.
     * Prevents futher clicks from firing.
     * @param event - Event from Moveable.
     */
    const onRotateLayerEnd = ({ lastEvent, inputEvent }: OnRotateEnd): void => {
        inputEvent && inputEvent.stopPropagation();
        inputEvent && inputEvent.preventDefault();
        lastEvent && saveNewPosition();
        resetMoveable();
    };

    const [showDragLayer, setShowDragLayer] = useState<boolean>(true);
    const [dragLayer, setDragLayer] = useState<DragLayerPosition>(calculateDragLayerPosition(format.key, targetLayer, zoomLevel, rotation, measurePoint));
    const [showControls, setShowControls] = useState<boolean>(
        shouldShowControls(+layerProperties.properties.width.value, +layerProperties.properties.height.value, layerProperties.properties.display)
    );

    /**
     * Updates the drag layer position when the layer properties change.
     */
    useEffect(() => {
        setDragLayer(calculateDragLayerPosition(format.key, targetLayer, zoomLevel, rotation, measurePoint));
    }, [layerProperties, currentTime, targetLayer, layers]);

    /**
     * Determine if the editor should be shown.
     * If the editor format is set, it will only show the editor for that format.
     * If the selected format is set, it will show the editor for the selected format.
     * If the selected format is set to general, it will show the editor for all formats.
     */
    const showEditor = (() => {
        if (layerProperties.properties.display !== undefined && layerProperties.properties.display === false) {
            return false;
        }

        // Don't show the editor when the current time is outside the visiblity.
        const secondsInStamp = TimelineHelpers.secondsToStamp(currentTime);
        const visibilty = TimelineHelpers.getLayerVisibility(layer.key, undefined, selectedFormats[0]);
        if (secondsInStamp < visibilty[0] || secondsInStamp > visibilty[1]) {
            return false;
        }

        if (selectedFormats?.includes(format.key)) {
            return true;
        }

        if (selectedFormats?.[0] === 'general') {
            return true;
        }
    })();

    return {
        moveableRef,
        showEditor,
        showControls,
        altKeyPressed,
        shiftKeyPressed,
        showDragLayer,
        dragLayer,
        targetLayer,
        dragTarget,
        onDragLayerStart,
        onDragLayer,
        onDragLayerEnd,
        onResizeLayerStart,
        onResizeLayer,
        onResizeLayerEnd,
        onRotateLayerStart,
        onRotateLayer,
        onRotateLayerEnd
    };
};

export default useEditor;
