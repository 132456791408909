import React, { CSSProperties, memo, useMemo } from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import { SceneHelpers } from 'helpers/scene.helpers';
import Translation from 'components/data/Translation';
import Template, { DesignerSettings, State, TemplateData, View } from 'components/template-designer/types/template.type';
import Format from 'components/template-designer/types/format.type';
import Layer from 'components/template-designer/types/layer.type';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import FrameType from 'components/template-designer/types/frameTypes.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { FOOTER_HEIGHT_BIG, FOOTER_HEIGHT_SMALL, TEMPLATE_DESIGNER_TOOLTIP_DELAY } from 'components/template-designer/constants';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import FrameTypeHelpers from 'components/template-designer/helpers/frame-type.helpers';
import { TemplateVersionHelpers } from 'components/template-designer/helpers/template-version.helpers';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';
import { LayerPropertiesHelpers } from 'components/template-designer/helpers/layer-properties.helpers';
import { StylingHelpers } from 'components/template-designer/helpers/styling.helpers';
import { CanvasHelpers } from 'components/template-designer/helpers/canvas.helpers';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { LayoutGrid as LayoutGridType } from 'components/template-designer/types/formatProperties.type';
import TemplateDesignerStore, { MultiModel } from 'components/template-designer/data/template-designer-store';
import { ActiveAnimationType } from 'components/template-designer/types/animation.type';
import cssStringToObject from '../utils/cssStringToObject';
import LayerContainer from './layerContainer';
import LayoutGrid from './layoutGrid';
import Dropdown from '../../ui-components/dropdown';
import { generateLines } from '../../layer-edit/components/sections/layout-grid-section/utils/generate-lines';
import { FormatDropdownOptions } from '../../ui-base/format-dropdown-options';

interface Props {
    format: Format;
    zoomLevel: number;
    preview?: boolean;
    frameType: View['frameType'];
    selectLayer?: (layer: Layer | null, formatKey: Format['key'] | null) => void;
    customLayerProperties?: Template['layerProperties'];
    showLayers?: boolean;
}

interface Data {
    generalBaseLayerProperties: Template['layerProperties']['general']['base']['properties'];
    formatBaseLayerProperties: Template['layerProperties']['format']['base']['properties'];
    generalLayerProperties: Template['layerProperties']['general']['main']['properties'];
    formatLayerProperties: Template['layerProperties']['format']['main']['properties'];
    layers: Template['layers'];
    showLayoutGrid: View['showLayoutGrid'];
    selectedLayers: State['selectedLayers'];
    selectedFormats: State['selectedFormats'];
    customCSS: DesignerSettings['customCSS'];
    customJS: DesignerSettings['customJS'];
    showTab: View['showTab'];
    disableBase: DesignerSettings['disableBase'];
    enableAnimations: DesignerSettings['enableAnimations'];
    enableLottie: DesignerSettings['enableLottie'];
    formatsWithAudio: State['formatsWithAudio'];
    templateType: TemplateData['type'];
    layoutGridFormat: LayoutGridType;
    layoutGridGeneral: LayoutGridType;
}

const CanvasFormat = memo(({ selectLayer, zoomLevel, frameType, preview, format, customLayerProperties, showLayers = true }: Props) => {
    const fields: { [key: string]: string } = {
        layers: 'layers',
        showLayoutGrid: 'view.showLayoutGrid',
        selectedLayers: 'state.selectedLayers',
        selectedFormats: 'state.selectedFormats',
        customCSS: 'designerSettings.customCSS',
        customJS: 'designerSettings.customJS',
        showTab: 'view.showTab',
        disableBase: 'designerSettings.disableBase',
        enableAnimations: 'designerSettings.enableAnimations',
        enableLottie: 'designerSettings.enableLottie',
        formatsWithAudio: 'state.formatsWithAudio',
        templateType: 'templateData.type'
    };

    if (!customLayerProperties) {
        fields.generalBaseLayerProperties = 'layerProperties.general.base.properties';
        fields.formatBaseLayerProperties = `layerProperties.${format.key}.base.properties`;
        fields.generalLayerProperties = `layerProperties.general.${frameType}.properties`;
        fields.formatLayerProperties = `layerProperties.${format.key}.${frameType}.properties`;
    }

    // Only get the layout grid data if it is enabled.
    const showLayoutGrid = getTemplateData<View['showLayoutGrid']>('view.showLayoutGrid');
    if (showLayoutGrid) {
        fields.layoutGridFormat = `layerProperties.${format.key}.${frameType}.properties.layoutGrid`;
        fields.layoutGridGeneral = `layerProperties.general.${frameType}.properties.layoutGrid`;
    }

    const {
        layers,
        selectedLayers,
        selectedFormats,
        customCSS,
        customJS,
        showTab,
        disableBase,
        enableAnimations,
        enableLottie,
        formatsWithAudio,
        templateType,
        layoutGridFormat,
        layoutGridGeneral,
        ...rest
    } = useComponentStore<Data>('TemplateDesigner', { fields });

    const layersShouldReverse = useMemo(() => !TemplateVersionHelpers.layersShouldNotReverse(), []);
    const shouldShowBaseFrameAbove = useMemo(() => TemplateVersionHelpers.shouldShowBaseFrameAbove(), []);

    // Actual layout grid data.
    const layoutGrid = useMemo(() => generateLines(format, layoutGridFormat, layoutGridGeneral), [layoutGridFormat, layoutGridGeneral]);
    const frameLayers = useMemo(
        () => LayerHelpers.getLayers(layers, frameType, enableAnimations, enableLottie, layersShouldReverse),
        [layers, frameType, enableAnimations, enableLottie, layersShouldReverse]
    );

    const baseLayers = useMemo(() => {
        if (disableBase) return [];
        return LayerHelpers.getLayers(layers, 'base', enableAnimations, enableLottie, layersShouldReverse);
    }, [disableBase, layers, enableAnimations, enableLottie, layersShouldReverse]);

    const { generalBaseLayerProperties, formatBaseLayerProperties } = useMemo(() => {
        if (!customLayerProperties) {
            let formatBaseLayerProperties = rest.formatBaseLayerProperties;
            if (Array.isArray(formatBaseLayerProperties)) {
                formatBaseLayerProperties = {};
            }

            return {
                generalBaseLayerProperties: rest.generalBaseLayerProperties,
                formatBaseLayerProperties: formatBaseLayerProperties
            };
        }

        let formatBaseLayerProperties = customLayerProperties[format.key].base.properties ?? {};
        if (Array.isArray(formatBaseLayerProperties)) {
            formatBaseLayerProperties = {};
        }

        return {
            generalBaseLayerProperties: customLayerProperties.general.base.properties ?? {},
            formatBaseLayerProperties: formatBaseLayerProperties
        };
    }, [customLayerProperties, rest.generalBaseLayerProperties, rest.formatBaseLayerProperties]);

    const { generalLayerProperties, formatLayerProperties } = useMemo(() => {
        if (!customLayerProperties) {
            let formatLayerProperties = rest.formatLayerProperties;
            if (Array.isArray(formatLayerProperties)) {
                formatLayerProperties = {};
            }

            return {
                generalLayerProperties: rest.generalLayerProperties,
                formatLayerProperties: formatLayerProperties ?? {}
            };
        }

        let formatLayerProperties = customLayerProperties[format.key][frameType].properties ?? {};
        if (Array.isArray(formatLayerProperties)) {
            formatLayerProperties = {};
        }

        return {
            generalLayerProperties: customLayerProperties.general[frameType].properties,
            formatLayerProperties: formatLayerProperties ?? {}
        };
    }, [customLayerProperties, rest.generalLayerProperties, rest.formatLayerProperties]);

    const baseLayerProperties = useMemo(
        () => merge({}, generalBaseLayerProperties, formatBaseLayerProperties),
        [generalBaseLayerProperties, formatBaseLayerProperties]
    );
    const layerProperties = useMemo(() => merge({}, generalLayerProperties, formatLayerProperties), [generalLayerProperties, formatLayerProperties]);

    const isActive = selectedFormats.includes(format.key);
    const isBlurred = selectedFormats[0] !== 'general' && !selectedFormats.includes(format.key);
    const showHover = showTab === 'hover';

    const customCSSStyling = {
        base: baseLayerProperties.customCSS ?? '',
        format: layerProperties.customCSS ?? ''
    };

    let style: CSSProperties = {
        ...StylingHelpers.getLayerStyle({
            ...layerProperties,
            ...StylingHelpers.getLayerStyle(get(layerProperties, `${format.key}.${frameType}.hover.properties`, {})),
            width: { value: format.width, unit: 'px' },
            height: { value: format.height, unit: 'px' }
        })
    };

    const baseStyle: CSSProperties = (() => {
        if (disableBase) return {};

        return {
            ...StylingHelpers.getLayerStyle({
                ...baseLayerProperties,
                position: 'absolute'
            }),
            ...cssStringToObject(customCSSStyling.base).attributes,
            width: format.width,
            height: format.height
        };
    })();

    if (customCSS) {
        style = merge(style, cssStringToObject(customCSSStyling.format).attributes);
    }

    if (showHover) {
        const hover = layerProperties.hover;

        if (hover?.properties?.enableProperties?.background) {
            style.background = StylingHelpers.convertColor(hover.properties.background);
        }

        if (hover?.properties?.enableProperties?.borderColor) {
            style.borderColor = StylingHelpers.convertColor(hover.properties.borderColor);
        }

        if (hover?.properties?.enableProperties?.opacity) {
            style.opacity = hover.properties.opacity;
        }
    }

    const hideFooter = preview || (templateType !== 'dynamicPDFDesigned' && zoomLevel < 0.2) || (format.width < 50 && zoomLevel < 1.25);
    const footerHeight = templateType === 'dynamicPDFDesigned' || zoomLevel >= 0.5 ? FOOTER_HEIGHT_BIG : FOOTER_HEIGHT_SMALL;

    const footerStyle = {
        transform: `scale(${1 / zoomLevel})`,
        transformOrigin: 'left top',
        width: zoomLevel * 99.9 + '%',
        height: footerHeight
    };

    const resetAllOverwritesAvailable = (() => {
        if (preview) return false;
        if (Object.keys(formatLayerProperties).length > 0) return true;
        return FormatHelpers.formatHasOverwrites(format.key);
    })();

    const resetLayerOverwritesAvailable = (() => {
        if (preview) return false;
        if (!selectedLayers || selectedLayers.length === 0) return false;
        return FormatHelpers.layerHasOverwrites(format.key, selectedLayers?.[0]?.key);
    })();

    const hasResetOptions = resetAllOverwritesAvailable || resetLayerOverwritesAvailable;
    const showResetButtonInFooter = hasResetOptions && zoomLevel * format.width >= 120;
    const showResetButtonInOptions = hasResetOptions && zoomLevel * format.width < 120;

    const showVolumeIcon = (() => {
        if (zoomLevel * format.width < 120) return false;

        // Check for video and audio layers
        const videoLayers = LayerHelpers.findLayerByType('video');
        const audioLayers = LayerHelpers.findLayerByType('audio');
        return videoLayers || audioLayers;
    })();

    /* (de)select format with checkbox */
    const handleCheckbox = (format: Format, isActive: boolean) => {
        let formatsCopy = cloneDeep(selectedFormats);

        const changes: MultiModel = [];
        // add format to selection
        if (!isActive) {
            const i = formatsCopy.findIndex((key) => key === 'general');
            if (i > -1) {
                formatsCopy.splice(i, 1);
            }

            formatsCopy.push(format.key);

            // Deselect layer when selecting format and select the visibility animation.
            const activeAnimations = getTemplateData<State['activeAnimations']>('state.activeAnimations');
            const layerSelected = activeAnimations.find((animation) => animation.layer !== '');

            if (layerSelected) {
                changes.push([
                    'state.activeAnimations',
                    [
                        {
                            type: ActiveAnimationType.Visibility,
                            layer: layerSelected.layer
                        }
                    ]
                ]);
            } else {
                changes.push(['state.activeAnimations', []]);
            }

            changes.push(['state.selectedFormats', formatsCopy]);
        }
        // remove format from selection
        else {
            const i = formatsCopy.findIndex((key) => key === format.key);
            formatsCopy.splice(i, 1);

            // set general as selection when no formats are selected
            if (formatsCopy.length === 0) {
                formatsCopy = ['general'];
            }

            changes.push(['state.selectedFormats', formatsCopy]);
            changes.push(['state.activeAnimations', []]);
        }

        TemplateDesignerStore.save(changes);
    };

    /** Turn off or on the audio for this format. */
    const toggleFormatAudio = () => {
        const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);
        const currentTime = SceneHelpers.getSceneTime(frameDuration);

        const newFormatsWithAudio = (() => {
            if (formatsWithAudio.includes(format.key)) {
                return formatsWithAudio.filter((f) => f !== format.key);
            }

            return [...formatsWithAudio, format.key];
        })();

        TemplateDesignerStore.save(
            [
                ['state.formatsWithAudio', newFormatsWithAudio],
                ['state.isPlaying', false, false]
            ],
            { saveToHistory: true }
        );
        SceneHelpers.pauseScene();
        CanvasHelpers.manageMediaLayers(false, currentTime, frameDuration);
        CanvasHelpers.manageLottieLayers(false, currentTime, frameDuration);
    };

    const resetAllOverwrites = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, formatKey: FrameType['key']) => {
        e.stopPropagation();
        e.preventDefault();

        LayerPropertiesHelpers.resetFormatOverwrites(formatKey);

        if (selectedLayers.length === 0) return;

        resetLayerOverwrites(e, formatKey);
    };

    const resetLayerOverwrites = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, formatKey: FrameType['key']) => {
        e.stopPropagation();
        e.preventDefault();

        if (selectedLayers.length === 0) return;

        LayerPropertiesHelpers.resetLayerOverwrites(formatKey, selectedLayers[0].key);
    };

    const getLayers = () => {
        if (!showLayers) return null;

        const commonProps = {
            zoomLevel,
            format,
            customLayerProperties,
            selectLayer,
            showHover,
            customCSS,
            customJS,
            preview,
            layoutGrid
        };

        const baseLayerContainer = !disableBase ? <LayerContainer {...commonProps} frameLayers={baseLayers} frameType="base" /> : null;

        if (frameType === 'base') return baseLayerContainer;

        const frameLayerContainer = <LayerContainer {...commonProps} frameLayers={frameLayers} frameType={frameType} />;

        return (
            <>
                {!disableBase && shouldShowBaseFrameAbove && baseLayerContainer}
                {frameLayerContainer}
            </>
        );
    };

    const showBaseFrame = frameType !== 'base' && !disableBase;

    return (
        <div
            className={classNames('template-designer__format', {
                ['template-designer__format--active']: isActive,
                ['template-designer__format--blurred']: isBlurred
            })}
            id={format.key}
            style={{
                height: (() => {
                    if (hideFooter) return format.height;
                    return format.height + footerHeight * (1 / zoomLevel);
                })(),
                width: format.width,
                outlineWidth: Math.floor(1 / zoomLevel),
                overflow: preview ? 'hidden' : 'visible',
                border: preview ? '1px solid #6d6d6d' : undefined
            }}
            key={format.key}>
            <div className="template-designer__format__format-container" style={{ width: format.width, height: format.height }}>
                <div className={classNames('template-designer__format__format-style', format.key)} style={{ ...style, position: 'absolute' }} />
                {showBaseFrame && (
                    <div
                        className={classNames('template-designer__format__format-style template-designer__format__format-style--base', format.key)}
                        style={{ ...baseStyle, position: 'absolute' }}
                    />
                )}
                {getLayers()}
                {showLayoutGrid && layoutGrid && !preview && <LayoutGrid format={format} frameType={frameType} layoutGrid={layoutGrid} />}
            </div>

            {!preview && !hideFooter && (
                <label className="template-designer__format__footer" style={footerStyle}>
                    <input
                        onClick={(event) => {
                            handleCheckbox(format, isActive);

                            // Prevent for the checkbox to be highlighted when pressing the escape key.
                            event.currentTarget.blur();
                        }}
                        readOnly
                        className="template-designer__format__footer__checkbox"
                        type="checkbox"
                        checked={isActive}
                    />

                    <Tooltip title={format.title} enterDelay={TEMPLATE_DESIGNER_TOOLTIP_DELAY}>
                        <div className="template-designer__format__footer__label">{format.title}</div>
                    </Tooltip>

                    {showHover && (
                        <Tooltip title={Translation.get('canvas.formats.hover', 'template-designer')} enterDelay={TEMPLATE_DESIGNER_TOOLTIP_DELAY}>
                            <div
                                className="template-designer__format__footer__hover"
                                data-tooltip={Translation.get('canvas.formats.hover', 'template-designer')}>
                                <Icon>touch_app</Icon>
                            </div>
                        </Tooltip>
                    )}

                    {showVolumeIcon && (
                        <Tooltip title={Translation.get('canvas.formats.volume', 'template-designer')} enterDelay={TEMPLATE_DESIGNER_TOOLTIP_DELAY}>
                            <IconButton className="template-designer__format__footer__volume" size="small" onClick={toggleFormatAudio}>
                                {formatsWithAudio.includes(format.key) ? <Icon>volume_up</Icon> : <Icon>volume_off</Icon>}
                            </IconButton>
                        </Tooltip>
                    )}

                    {showResetButtonInFooter && (
                        <Dropdown
                            trigger={
                                <Tooltip title={Translation.get('canvas.formats.reset', 'template-designer')} enterDelay={TEMPLATE_DESIGNER_TOOLTIP_DELAY}>
                                    <IconButton className="template-designer__format__footer__reset" size="small">
                                        <Icon>restart_alt</Icon>
                                    </IconButton>
                                </Tooltip>
                            }
                            items={[
                                {
                                    key: 'reset_all',
                                    icon: 'restart_alt',
                                    children: Translation.get('canvas.formats.resetAll', 'template-designer'),
                                    onClick: (event) => resetAllOverwrites(event, format.key),
                                    hide: !resetAllOverwritesAvailable
                                },
                                {
                                    key: 'reset_layer',
                                    icon: 'restart_alt',
                                    children: Translation.get('canvas.formats.resetLayer', 'template-designer'),
                                    onClick: (event) => resetLayerOverwrites(event, format.key),
                                    hide: !resetLayerOverwritesAvailable
                                }
                            ]}
                        />
                    )}

                    <FormatDropdownOptions
                        trigger={
                            <IconButton className="template-designer__format__footer__format-options" size="small">
                                <Icon>more_vert</Icon>
                            </IconButton>
                        }
                        format={format}
                        hideOptions={{
                            changeTitle: true,
                            scroll: true,
                            resetOverwrites: !showResetButtonInOptions
                        }}
                    />
                </label>
            )}
        </div>
    );
});

CanvasFormat.displayName = 'CanvasFormat';

export default CanvasFormat;
